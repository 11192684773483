.carousel-text{
    position: absolute;
    top: 8px;
    left: 600px;
    color: white;
    text-align: left;
    /* font-size: 36px;
    font-weight: 600px; */
    /* background-image: linear-gradient(to right,blue,indigo,violet);	 */
    background: linear-gradient(transparent, gray);
    border-radius: 8px;
  }
  