
.akwaba-top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;    
    min-height:250px;
    background: url("../../clientapp/img/computer-algorithm.jpg");      
  }
  
  .akwaba-content{ 
  width:100%;
  }

  .akwaba-content-training{ 
    width:100%;
    height: 250px;
    background-color: aqua;
    }

    .akwaba-content-admin{ 
      width:100%;
      height: 250px;
      background-color: goldenrod;
      }
  
  .akwaba-lang {
    padding: 10px 16px;    
    top: 0;
    width: 100%; 
    background: -webkit-linear-gradient(#eee, #0ea1e6); 
  }
  
  .akwaba-container-items {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;   
  }
  
  
  .akwaba-container-items-adminpage {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;   
    background-color: white;
    width: 100%;
    list-style-type:none;
  }
  .aboutus-titles{
    width: 95%;
    /* background-color: silver; */
    border-radius: 20px;
    border: solid #0ea1e6;
  }
  .akwaba-container-items-aboutus{
    align-items: left;
    justify-content: center;
    text-align: left;
    border-radius: 10px;   
    background-color: white;
    width: 95%;
    list-style-type:none;
    margin:20px 10px 10px 20px;
    font-size: 20px;
  }
  
  .akwaba-tiles-navlinks {
    color: white;
    background-color: #0ea1e6;
    font-family: verdana;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  }
  .akwaba-services-title {
    padding: 10px 10x 10px 10px;
    font-family: verdana;
    font-size: 26px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .akwaba-tiles {
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: navy;
    border-radius:  5px;
    border: 5px;  
  }
  
  .akwaba-group-tiles{
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: navy;
    border-radius: 5px;
    border:  5px;
    border-color:  #0ea1e6;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  #signedin{
    color:magenta;
  }
