@import url("akwaba.css");
@import url("footer.css");
@import url("headersAndNavbars.css");
@import url("carousels.css");


.comingsoon{
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
  color: navy;
  border-radius: 5px;
  border:  5px;
  border-color: green; 
  padding-top: 50px;
  padding-bottom: 10px;
  height:100px;
  width: 70%;  
  margin-left: 18%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.container {
  width:100%
}


.content-akwaba {
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;
  color: goldenrod;
  width: 100%;
  background-color: #f4f7f8;
  min-height: 100%;
  padding: 20px;
  padding-bottom: 0px;
}

.content-justscript-akwaba {
  align-items: center;
  justify-content: center;  
  text-align: left;
  color: goldenrod;
  width: 80%;
  margin-left: 10%;
  background-color: #f4f7f8;
  min-height: 100%;
  padding: 10px 20px;
  padding-bottom: 100px;
}


.content-collapse {
  cursor: pointer;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
  border-radius: 0 0 5px 5px;
  padding: 15px;
  font-family: verdana;
  font-size: 14px;
}

.content-accordion {
  color: navy;
  font-family: verdana;
  font-size: 20px;
  text-align:justify ;
}


.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.col-25 {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
}

.col-75 {
  -ms-flex: 75%; /* IE10 */
  flex: 75%;
}

.col-25,
.col-50,
.col-75 {
  padding: 0 16px;
}

input[type="text"] {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="email"] {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="password"] {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

input[type="submit"] {
  background-color: #4caf50;
  width: 50%;
  margin-bottom: 20px;
  height: 50px;
  border-radius: 3px;
}

input[type="submit"]:hover {
  background-color: #0099ff;
}

textarea {
  width: 50%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

label {
  margin-bottom: 10px;
  display: block;
}

.icon-container {
  margin-bottom: 20px;
  padding: 7px 0;
  font-size: 24px;
}

.btn {
  background-color: #0ea1e6;
  color: white;
  padding: 12px;
  margin: 10px 0;
  border: none;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  font-size: 17px;
}

.btn:hover {
  background-color: #45a049;
}

span.price {
  float: right;
  color: grey;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (and change the direction - make the "cart" column go on top) */
@media (max-width: 800px) {
  .row {
    flex-direction: column-reverse;
  }
  .col-25 {
    margin-bottom: 20px;
  }

  .slideshow_deploy {   
   display:none
    }

    .slideshow_deploy_web {
      border-radius:40px;    
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;  
      max-width:300px;
      max-height:300px;
      width: 250px;
      height: 300px;
      margin: auto; 
      background: url("../../clientapp/img/mobiledisplay1.jpg");      
    }

    .akwaba-content_deploy{ 
      width:100%;      
      }   
  
  p {
    text-align: center;
    font-weight: 500;
  }

  select,
  input.error {
    border-color: red;
  }

  .div-separator {
    top: 40px;
    bottom: 40px;
  }

  .toggleelements{
    color:green;
    font-weight: bold;
    font-size:20px;
  }

  #nprogress .bar {
    background: red !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px red, 0 0 5px red !important;
}

#nprogress .spinner-icon {
    border-top-color: red !important;
    border-left-color: red !important;
}
}
